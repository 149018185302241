const accessLifeinfoTab = (() => {
  if (document.querySelector("body#pageAccess") !== null) {



    let sW = window.innerWidth;

    let secLocation = document.querySelector("#jsSecLocation")
    let tabMap = secLocation.querySelectorAll(".js-tab-map")

    let secLifeinfo = document.querySelector("#jsSecLifeinfo")
    let tabBtn = secLifeinfo.querySelectorAll(".js-tab-btn")
    let tabCntnt = secLifeinfo.querySelectorAll(".js-tab-cntnt")


    tabBtn.forEach((element, index) => {
      element.addEventListener('click', () => {
        for (let i = 0; i < tabMap.length; i++) {
          tabMap[i].classList.remove('act');
        }
        for (let i = 0; i < tabBtn.length; i++) {
          tabBtn[i].classList.remove('act');
        }
        for (let i = 0; i < tabCntnt.length; i++) {
          tabCntnt[i].classList.remove('act');
        }

        if (index == 0) {
          tabMap[0].classList.add('act');
          tabBtn[0].classList.add('act');
          for (let i = 0; i < tabCntnt.length; i++) {
            tabCntnt[i].classList.add('act');
          }
        } else {
          tabMap[index].classList.add('act');
          tabBtn[index].classList.add('act');
          tabCntnt[index - 1].classList.add('act');
        }

      });
    });



  }
})()
export default accessLifeinfoTab;